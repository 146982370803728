/* -------------------------------------------------------------------------- */
/* --- TEMPLATE STANDARD ---------------------------------------------------- */
/* -------------------------------------------------------------------------- */

/* --- MIXIN ---------------------------------------------------------------- */
.box-shadow(@string) {
    box-shadow: @string ;
    -moz-box-shadow: @string;
    -webkit-box-shadow: @string;
}

.box-shadow(@string,@string1) {
    box-shadow: @string,@string1;
    -moz-box-shadow: @string,@string1;
    -webkit-box-shadow: @string,@string1;
}

.text-shadow(@string) {
    text-shadow: @string;
    -moz-text-shadow: @string;
    -webkit-text-shadow: @string;
}

.opacity(@alpha:0, @palpha:percentage(@alpha)) {
    -webkit-opacity: @alpha;
    -moz-opacity: @alpha;
    opacity: @alpha;
    filter: alpha(opacity=@palpha); /* For IE8 and earlier */
    zoom: 1;
}

.transition(@string : all .3s linear) {
    transition: @string;
    -moz-transition: @string;
    -webkit-transition: @string;
}

.transform(@string) {
    transform:  @string;
    -moz-transform: @string;
    -webkit-transform: @string;
}

.border-radius(@radius) {
    border-radius: @radius;
    -moz-border-radius: @radius;
    -webkit-border-radius: @radius;
}

.keyframes(@name, @arguments) {
    @-webkit-keyframes @name { @arguments(); }
    @-moz-keyframes @name { @arguments(); }
    @-ms-keyframes @name { @arguments(); }
    @-o-keyframes @name { @arguments(); }
    @keyframes @name { @arguments(); }
}

.animation(@arguments) {
	-webkit-animation: @arguments;
	-moz-animation: @arguments;
	animation: @arguments;
}

/* --- COMMON CLASS SITO BASE ----------------------------------------------- */
.align-right {text-align: right;}
.align-left {text-align: left;}
.align-center {text-align: center;}

.valign-middle {vertical-align: middle !important; }

.absolute {position: absolute;}
.relative {position: relative;}
.fixed {position: fixed;}

.uppercase {text-transform: uppercase;}
.lowercase {text-transform: lowercase;}
.capitalize {text-transform: capitalize;}

.absolute-link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
}

.background-cover {
    position: relative;
    height: 100%;

    .image-cover {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

.table-cell {
    display: table;

    &-full {
        display: table;
        width: 100%;
    }

    & > div {
        float: none;
        display: table-cell;
        vertical-align: top;
    }
}

.line {
    position: relative;
    overflow: hidden;

    .mod-title {
        position: relative;

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 30px;
            width: 1px;
            background-color: fade(@white, 50%);
            z-index: 2;
            height: 10000%;
        }

        &:before {
            bottom: 100%; /* padding class content */
        }

        &:after {
            top: 100%;
        }
    }
}

.all-line {
    position: relative;
    overflow: hidden;
    top: -30px;

    .mod-title {
        position: relative;
        padding-top: -15px !important;

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 30px;
            width: 1px;
            background-color: fade(@white, 50%);
            z-index: 2;
            height: 10000%;
        }

        &:before {
            bottom: 100%; /* padding class content */
        }

        &:after {
            top: 0%;
        }
    }
}




.appearance() {
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
}

/* Style title indent */
.offset-title() {
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
    z-index: 3;
    margin-left: -30px;

    h3,
    h4,
    p {
        margin: 0;
    }

    h3 {
        font-size: 2em;
    }

    h3,
    h4 {
        text-transform: uppercase;
    }

    p {
        font-size: 1.1em;
    }
}

/* Animation hover gallery image */
.zoom-image {
    .inner-item:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: @brown;
        z-index: 2;
        .transition(all .4s ease-in-out);
    }

    .background-cover {

        &:before {
            font-size: 32px;
            color: @white;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            margin-left: -30px;
            margin-top: -30px;
            width: 60px;
            height: 60px;
            text-align: center;
            line-height: 60px;
            background-color: fade(@beige, 30%);
            .opacity(0);
            .border-radius(50%);
            .transition(all .6s ease-in-out);
            .box-shadow(0 0 12px fade(darken(@brown, 15%), 15%));
        }
    }

    .image-cover {
        /*.transform(scale(1.2));*/
        .opacity(1);
        .transition(transform .3s ease-in-out, opacity .3s linear;);
    }

    &:hover,
    &:focus {
        .inner-item:before {
            height: 10px;
        }

        .background-cover {
            &:before {
                .opacity(1);
            }
        }

        .image-cover {
            .opacity(.3);
            .transform(scale(1));
        }
    }
}

.textarea {
    padding: 20px;
}


/* -------------------------------------------------------------------------- */
/* --- END TEMPLATE STANDARD ------------------------------------------------ */
/* -------------------------------------------------------------------------- */


/* --- COLORS --------------------------------------------------------------- */
@brown  : #372b20;
@brown-dark  : #2a1d10;
@beige  : #b9ada1;
@orange : #cf892f;
@orange-dark : #af521b;
@white  : #ffffff;
@black: #111;

.bg-brown {
    color: @white;
    background-color: @brown;
}

.bg-brown-dark {
    color: @white;
    background-color: @brown-dark;

    .btn {
      position: absolute;
      width: 100%;
      background-color: @orange;
      color: white;
      border: none;
    }
}

.bg-beige {
    color: @brown;
    background-color: @beige;
}

.bg-white {
    background-color: @white;

    &.line .mod-title {
        &:before,
        &:after {
            background-color: fade(@brown, 50%);
        }
    }
    &.all-line .mod-title {
        &:before,
        &:after {
            background-color: fade(@brown, 50%);
        }
    }
}

/* --- BUTTON --------------------------------------------------------------- */
.btn() {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    font-size: .8em;
    font-weight: bold;
    text-transform: uppercase;
    vertical-align: middle;
    -ms-touch-action: manipulation;
        touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 0 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 40px;
    line-height: 40px;
    .border-radius(40px);
    .transition();
}

.btn-color(@background, @border: fade(#fff,0), @color: #fff) {
    color: @color;
    background-color: @background;
    border: 0;

    &:hover,
    &:focus {
         color: @color;
        background-color: darken(@background, 12%);
    }

    &[class*=" icon-"] {
        padding-right: 40px;

        &:before {
            font-size: 12px;
            font-weight: bold;
            position: absolute;
            top: 50%;
            right: 15px;
            margin-top: -6.5px;
        }


    }

    &-border {
        color: @background;
        background: none;
        border: 1px solid @background;

        &:hover
        &:focus {
        color: @color;

            & when (@background = @white) {
                color: @brown;
            }

            border: 1px solid fade(@background, 0);
            background-color: @background;
        }
    }
}

.btn {
    .btn();

    &.btn-xs {
        height: 25px;
        line-height: 25px;
    }

    &.btn-white {
        .btn-color(@white);
          &:hover {
            color: #372b20;
          }
    }

    &.btn-brown {
        .btn-color(@brown);
    }
    &.btn-orange {
        .btn-color(@orange-dark);
    }


    &.btn-beige {
        .btn-color(@beige);
    }

    &.btn-orange {
        .btn-color(@orange);
        .box-shadow(0 2px 4px fade(darken(@orange, 30%), 30%));
    }
    &.btn-prenota {
      border: solid 1px;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      padding: 0 15%;
      font-size: 1em;
      font-weight: 600;
      margin: 5px 0;

    }



}

.btn-circle {
    width: 50px;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    text-indent: -999px;
    text-align: center;
    padding: 0;

    &:before {
        font-size: 20px;
        position: absolute;
        left: 0;
        top: 0;
        // line-height: 50px !important;
        height: 100%;
        width: 100%;
        text-indent: 0px;
    }

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        text-indent: 0;
        width: 100%;
        height: 100%;
        .border-radius(50%);
    }
}

/* --- FONTS ---------------------------------------------------------------- */
@serif      : 'Martel', serif;
@sans-serif : 'Titillium Web', sans-serif;
@Bentham    : 'Bentham', serif;

.font-logo {
    font-family: @Bentham;
}

@font-face {
    font-family: "st-mauritius";
    src:url("../fonts/st-mauritius.eot");
    src:url("../fonts/st-mauritius.eot?#iefix") format("embedded-opentype"),
        url("../fonts/st-mauritius.woff") format("woff"),
        url("../fonts/st-mauritius.ttf") format("truetype"),
        url("../fonts/st-mauritius.svg#st-mauritius") format("svg");
    font-weight: normal;
    font-style: normal;
}

[data-icon]:before {
    font-family: "st-mauritius" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "st-mauritius" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-click:before {
  content: "\61";
}
.icon-next:before {
  content: "\62";
}
.icon-up:before {
  content: "\63";
}
.icon-marker:before {
  content: "\64";
}
.icon-down:before {
  content: "\65";
}
.icon-phone:before {
  content: "\66";
}
.icon-edit:before {
  content: "\67";
}
.icon-arrow-right:before {
  content: "\74";
}
.icon-arrow-up:before {
  content: "\69";
}
.icon-arrow-down:before {
  content: "\6a";
}
.icon-arrow-left:before {
  content: "\74";
}
.icon-photo:before {
  content: "\6c";
}
.icon-instagram:before {
  content: "\6d";
}
.icon-calendar-2:before {
  content: "\6e";
}
.icon-email:before {
  content: "\6f";
}
.icon-star:before {
  content: "\70";
  line-height: 0.1em;
}
.icon-calendar:before {
  content: "\71";
}
.icon-facebook:before {
  content: "\72";
}
.icon-left:before {
  content: "\73";
}
.icon-right:before {
  content: "\74";
}
.icon-arrow-down-menu:before {
  content: "\65";
}

.fancybox-next,
.fancybox-prev,
.fancybox-close {
    font-size: 40px;
    color: @white;

    &:before {
        position: absolute;
        top: 15px;
        left: 8px;
        color: @white;
        font-family: "st-mauritius" !important;
    }

    &:hover {
        color: fade(@white, 60%);
        text-decoration: none;
    }
}

.fancybox-close:before {
    content: "+";
    .transform(rotate(45deg));
    font-size: 2em;
    top: -60%;
    left: 30%;

}

.fancybox-next:before {
    content: "\74";
    line-height: 0.8em;
}

.fancybox-prev:before {
    content: "\73";
    line-height: 0.8em;
}

.transition(@string: all .3s linear) {
    transition: @string;
    -moz-transition: @string;
    -webkit-transition: @string;
}
