body {
    overflow-x: hidden;
}

/*LESS LISTA CAMERE*/
.mod-full-slide.bg-white {
    height: auto;
    display: block;
}

.new-mod-list-article-camere {
    padding-top: 30px;
    padding-bottom: 30px;

    .item {
        float: left;
        cursor: pointer;
        width: 33.33%;
        height: 35vh;
        padding: 60px;
        border: 8px solid @white;
        position: relative;
    	overflow: hidden;
    	text-align: center;

        @media all and (max-width: 1024px) {
            width: 100%;
        }
          @media all and (max-width: 767px) {
            padding:2vh;
        }
        
        .image-cover {
            background-position: center center;
            .opacity(.5);
            .transform(scale(1.2));
            .transition(transform .6s linear, opacity .3s linear;);
        }

        .mod-title {
            position: relative;
            z-index: 2;

            h5 {
                margin: 0;
                font-size: 1em;
            }

            .offset-title;
        }

        .btn-readmore {
            position: absolute;
            z-index: 2;
            bottom: 30px;
            left: 0;
            right: 30px;
            text-align: center;
        }

        &:hover,
        &:focus {
            .image-cover {
                .transform(scale(1));
                .opacity(.8);
            }

            .btn-readmore {

            }
        }
    }
}

/*blocco camere home*/
.swiper-container-camere-home {
    margin-bottom: 40px;
    position: relative!important;
    width: 100%;
    background-color: transparent;
    z-index: 3!important;

    >.swiper-container-camere-home {
        position: relative!important;
        width: 100%;
        z-index: 3!important;
    }

    .swiper-wrapper {
        width: 100%;
        opacity: 1!important;
        margin: 0 auto;

        .swiper-slide {
            position: relative;
            width: 60%!important;
            height: 35vw;

            @media all and (max-width: 1368px) {
                width: 50%!important;
            }

            @media all and (max-width: 1023px) {
                width: 100%!important;
                height: 60vw;
            }

            @media all and (max-width: 769px) {
                height: 70vw;
            }

            @media all and (max-width: 600px) {
                height: 80vw;
            }

            @media all and (max-width: 500px) {
                height: 100vw;
            }

            @media all and (max-width: 500px) {
                height: 100vw;
            }

            .photo {
                position: relative;
                height: 100%;
                width: 100%;

                & > div {
                    height: 100%;
                }

                &.shadow .image {
                    .box-shadow(inset 0 -100px 180px rgba(0,0,0,.2));
                }

                .image {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    z-index: 0;
                }

                &.absolute {
                    width: 100%;
                }
            }


            .wrap {
                width: 100%;
                height: 120px;
                display: flex;
                .opacity(0);
                .transition(all ease-in-out .3s);
                position: absolute;
                top: 0;
                left: 0;

                .text {
                    background-color: @beige;
                    color: white;
                    padding: 0 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @media all and (max-width: 769px) { 
                        font-size: .9em;
                        padding: 20px;
                    }

                    @media all and (max-width: 500px) { 
                        font-size: .8em;
                        padding: 15px;
                        text-align: left;
                    }

                    h3 {
                        margin: 0px;
                        font-size: 1.9em;
                        text-align: left;
                        line-height: .8em;
                        font-family: @sans-serif;
                        margin-bottom: 10px;

                        @media all and (max-width: 1700px) {
                            #RU &,
                            #DE & {
                                font-size: 1.5em;
                            }
                        }
                        @media all and (max-width: 1369px) {
                            #RU &,
                            #DE & {
                                font-size: 1em;
                            }
                        }
                    }
                }

                /*.size,
                .guests,
                .view {
                    padding: 0;
                    position: relative;
                    background-image: url(/images/textures/light-wave.png);
                    background-size: cover;
                    overflow: hidden;
                    color: @beige;
                    text-align: center;

                    .wrap-details {
                        position: absolute;
                        top: 50%;
                        width: 100%;
                        .transform(translateY(-50%));
                        display: flex;
                        justify-content: center;

                        >div {
                            font-size: 1.4em;

                            @media all and (max-width: 1700px) {
                                #RU &,
                                #DE & {
                                    font-size: 1.2em;
                                }
                            }
                            @media all and (max-width: 1369px) {
                                #RU &,
                                #DE & {
                                    font-size: 1em;
                                }
                            }
                            @media all and (max-width: 1200px) {
                                #RU & {
                                    font-size: .8em;       
                                }
                            }

                            >strong {
                                font-size: 1.6em;
                                line-height: 0;
                            }   

                            >span {
                                display: block;
                                line-height: 0;
                            }

                            >small {
                                font-size: .8em;
                            }
                        }
                        >span {

                            &:before {
                                font-size: 3.2em;
                            }
                        }
                    }

                }

                .view {
                    .wrap-details >div {
                        text-align: left;
                        >span {
                            line-height: 1.2em;
                        }
                        >small {
                            display: block;
                           line-height: 0;
                        }
                    }
                }

                .guests,
                .view {
                    border-left: 2px solid @beige;
                }*/ 
            }

            .mod-articles-category-readmore {
                .opacity(0);
                position: absolute;
                bottom: 30px;
                right: 30px;
                z-index: 4;

                @media all and (max-width: 1023px) {
                    bottom: 30px;
                    right: 30px;
                }

                a.btn {
                    border: 1px solid @white;
                    background-color: fade(@white, 0%);
                    color:@white;
                    .transition(all ease-in-out .3s);
                    padding-right: 35px!important;

                    &:before {
                        position: absolute;
                        line-height: 40px;
                        right: 10px;
                    }

                    &:hover,
                    &:focus {
                        color: @black;
                        background-color:  @white;
                    }
                }

                @media all and (max-width: 500px) {
                    bottom: 15px;
                    right: 15px;

                    a.btn {
                        font-size: .9em;
                    }
                }
            }

            &-active {
                .wrap,
                .mod-articles-category-readmore {
                    .opacity(1);
                }
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 48px;
        height: 48px;
        background-color: @beige;
        text-align: center;
        .border-radius(50%);

        &:before {
            font-family: "Flaticon";
            color: @white;
            line-height: 48px;
            font-size: 1em;
        }

        @media all and (max-width: 500px) {
            width: 24px;
            height: 24px;

            &:before {
                line-height: 24px;
                font-size: .75em;
            }
        }
    }

    .swiper-button-next {
        right: 18%;
        margin-right: -36px;
        background-image: none;
        color: black;

        &:before {
            content: "\74";
            font-family: "st-mauritius";
        }
    }
    .swiper-button-prev {
        left: 18%;
        margin-left: -36px;
        background-image: none;
        color: black;

        &:before {
            content: "\73";
            font-family: "st-mauritius";
        }
    }

    @media all and (max-width: 1023px) {
        .swiper-button-next {
            right: 15px;
            margin-right: 0;
        }
        .swiper-button-prev {
            left: 15px;
            margin-left: 0px;
        }
    }

    .swiper-pagination-fraction, 
    .swiper-pagination-custom, 
    .swiper-container-horizontal > .swiper-pagination-bullets {
        font-family: @serif;
        bottom: 0;
        top: auto!important;
        left: 20%!important;
        line-height: 1em;
        background-color: transparent;
        /*.box-shadow(0 0 0px 1px @white);*/
        padding: 30px;
        font-size: 2.2em;
        color: @white;
        width: auto!important;

        @media all and (max-width: 1368px) {
            left: 25%!important;
            font-size: 1.6em;
        }

        @media all and (max-width: 1023px) {
            font-size: 1.4em;
            left: 0!important;
        }
    }
}